<script setup lang="ts">
import { ChevronDownIcon } from '@cfgtech/icons'

import { CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import type { SbFaqItemStoryblok } from '~/types/storyblok'

const { blok } = defineProps<{ blok: SbFaqItemStoryblok }>()

const renderedText = computed(() => blok.text ? renderRichText(blok?.text) : '')
</script>

<template>
  <AppCard class="flex flex-col !p-7">
    <Disclosure v-slot="{ open }">
      <DisclosureButton class="flex justify-between gap-2">
        <CfgTypography
          class="mb-2 text-left font-nunito !font-extrabold"
          :size="CfgTypographySizes.h5"
        >
          {{ blok.title }}
        </CfgTypography>

        <ChevronDownIcon
          class="shrink-0 text-[24px] transition-transform"
          :class="{ 'rotate-180 text-brand': open }"
        />
      </DisclosureButton>

      <DisclosurePanel class="mt-3 overflow-hidden transition-all duration-300 ease-in-out">
        <CfgTypography
          class="mb-4"
          :html="renderedText"
        />
      </DisclosurePanel>
    </Disclosure>
  </AppCard>
</template>
